import React from 'react';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, DeviceSizes } from '@conventic-web/internal.global';
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import { Address } from '@conventic-web/internal.cvt.basic.address';

/*---Queries---*/
import AddressDataQuery from '../../../utility/data/address/address-query';
import ImpressumTabularDataQuery from './impressumTabular-query';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const ImpressumTabular = () => {
  const styles = {
    root: {
      width: '20%',
      marginRight: '10%',
      [`@media(max-width: ${DeviceSizes.laptop})`]: {
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
    markdown: {
      '& > h2': {
        fontSize: '1em',
        paddingBottom: '0em',
        marginBottom: '0.2em',
        marginTop: '1.5em',
      },
      '& > p': {
        marginBottom: '0em',
      },
      '& > p > a': {
        color: CvtColorSchema.green,
        fontWeight: 'normal',
        textDecoration: 'none',
      },
    },
  };
  const tabData = ImpressumTabularDataQuery();
  const tabularData = tabData.nodes[0].frontmatter.impressumTabularData;
  const addData = AddressDataQuery();
  const addressData = addData.nodes[0].frontmatter;

  return (
    <Box component="div" sx={styles.root}>
      <Headline text="Impressum" variant={'h3'} />
      <p>
        conventic Gesellschaft für IT-Beratung und Applikationsentwicklung mbH
        <Address
          street={addressData.utilityAddressBonn.street}
          zipcode={addressData.utilityAddressBonn.zipcode}
          district={addressData.utilityAddressBonn.district}
          place={addressData.utilityAddressBonn.place}
          country={addressData.utilityAddressBonn.country}
          fon={addressData.utilityAddressBonn.fon}
        />
      </p>
      <Box
        component="div"
        sx={styles.markdown}
        dangerouslySetInnerHTML={{
          __html: md.render(tabularData),
        }}
      />
    </Box>
  );
};

export default ImpressumTabular;
